import React, { useRef, useState } from 'react';
import './App.css';
import Screenshot1 from './assets/screens/frames/screenshot1.png';
import Screenshot2 from './assets/screens/frames/screenshot2.png';
import Screenshot3 from './assets/screens/frames/screenshot3.png';
import Screenshot4 from './assets/screens/frames/screenshot4.png';
import Screenshot5 from './assets/screens/frames/screenshot5.png';
import Screenshot6 from './assets/screens/frames/screenshot6.png';
import Screenshot7 from './assets/screens/frames/screenshot7.png';
import Screenshot8 from './assets/screens/frames/screenshot8.png';
import Screenshot9 from './assets/screens/frames/screenshot9.png';
import Screenshot10 from './assets/screens/frames/screenshot10.png';
import Screenshot11 from './assets/screens/frames/screenshot11.png';
import Screenshot12 from './assets/screens/frames/screenshot12.png';
import {AspectRatio, Box, Center, Container, Divider, Title } from '@mantine/core';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import AppleLogo from './assets/apple_store.svg';
import GoogleLogo from './assets/google_store.png';
import VomLogo from './assets/vom_logo.svg';

interface ImageSize {
  width: number;
  height: number;
}

const GALLERY_IMAGE_SIZE: ImageSize = {
  width: 231,
  height: 500,
}

const FULL_IMAGE_SIZE: ImageSize = {
  width: 1170,
  height: 2532,
}

function App() {
  const galleryRef = useRef<ImageGallery>(null);
  const [imageSize, setImageSize] = useState<ImageSize>(GALLERY_IMAGE_SIZE);
  const [startIndex, setStartIndex] = useState<number>(0);

  function onScreenChange(fullscreen: boolean) {
    setStartIndex(galleryRef.current ? galleryRef.current.getCurrentIndex() : 0);
    setImageSize(fullscreen ? FULL_IMAGE_SIZE :GALLERY_IMAGE_SIZE);
  }

  const images = [
    {
      original: Screenshot1,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
    {
      original: Screenshot2,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
    {
      original: Screenshot3,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
    {
      original: Screenshot4,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
    {
      original: Screenshot5,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
    {
      original: Screenshot6,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
    {
      original: Screenshot7,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
    {
      original: Screenshot8,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
    {
      original: Screenshot9,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
    {
      original: Screenshot10,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
    {
      original: Screenshot11,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
    {
      original: Screenshot12,
      originalWidth: imageSize.width,
      originalHeight: imageSize.height,
    },
  ];

  return (
    <>
      <Container mt="lg">
        <AspectRatio ratio={440 / 150} mx="auto" maw={400}>
          <img src={VomLogo} alt="V.O.M." />
        </AspectRatio>
        <Box mt="lg">
          <Center>
            <Title order={2}>Screenshots</Title>
          </Center>
          <Box mt="lg">
            <ImageGallery autoPlay={true} startIndex={startIndex} ref={galleryRef} onScreenChange={onScreenChange} showThumbnails={false} items={images} />
          </Box>
        </Box>
        <Divider my={50} />
        <Center>
          <Title order={2}>Video</Title>
        </Center>
        <Box mt="lg">
          <AspectRatio ratio={16 / 9}>
            <iframe
              src="https://www.youtube.com/embed/7zJqrESdSZQ?si=puaoAfv_gtHx-Ipj"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </AspectRatio>
        </Box>
        <Divider my={50} />
        <Center>
          <Title order={2}>Download App</Title>
        </Center>
        <Center>
          <Title order={5}>(Coming Soon)</Title>
        </Center>
        <Center mt="lg" mb={50}>
          <a href="#">
            <Box
              mr={10}
              sx={(theme) => ({
                height: 70,
                '@media (max-width: 40em)': {
                  height: 50,
                },
              })}
              component="img"
              alt="Get it on the App Store"
              src={AppleLogo}
            />
          </a>
          <a href="#">
            <Box
              component="img"
              sx={(theme) => ({
                height: 70,
                '@media (max-width: 40em)': {
                  height: 50,
                },
              })}
              alt="Get it on Google Play"
              src={GoogleLogo}
            />
          </a>
        </Center>
      </Container>
    </>
  );
}

export default App;
